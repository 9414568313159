export default {
  'slot1': "2241618900",
  'slot2': "2530243577",
  'slot3': "7120225271",
  'firebaseConfig': {
 apiKey: "AIzaSyCGaYGM9CA5PvXRC5_1g90-ajg9jvHZ7g8",
  authDomain: "news14-org.firebaseapp.com",
  projectId: "news14-org",
  storageBucket: "news14-org.appspot.com",
  messagingSenderId: "241291503550",
  appId: "1:241291503550:web:a40b9ea9abb52f03647bef",
  measurementId: "G-R93SJWXSB8"
  }
}